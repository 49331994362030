<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row justify-content-between">
            <div class="col-auto">
              <h5 class="card-title">
                {{ $t("metrics.trends") }}
              </h5>
            </div>
            <div class="col-auto">
              <div class="btn-toolbar" role="toolbar">
                <div class="btn-group mr-4" role="group">
                  <select
                    v-model="metric"
                    class="trends-metric-select selectpicker"
                    data-width="fit"
                  >
                    <option
                      v-for="(trend, key) in trends"
                      :key="key"
                      :value="key"
                    >
                      {{ trend.label }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <LineChart
                :chart-data="chartData"
                :chart-options="chartOptions"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from "merchant/dashboard/line_chart.vue";

class CurrencyRenderer {
  static displayValue(value, locale, currency) {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currency,
      maximumFractionDigits: 0,
    }).format(value);
  }
}

class PercentageRenderer {
  static displayValue(value, _locale, _currency) {
    return value + "%";
  }
}

class ScalarRenderer {
  static displayValue(value, _locale, _currency) {
    return value;
  }
}

const renderers = {
  currency: CurrencyRenderer,
  percentage: PercentageRenderer,
  scalar: ScalarRenderer,
};

export default {
  components: {
    LineChart,
  },
  props: {
    trends: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      metric: Object.keys(this.trends)[0],
      locale: window.pie.locale,
      currency: window.pie.currency,
    };
  },
  computed: {
    valueRenderer: function () {
      const format = this.currentTrend.format || "scalar";
      return renderers[format];
    },

    chartOptions: function () {
      return {
        plugins: {
          legend: {
            display: false,
          },

          tooltip: {
            callbacks: {
              title: (context) => {
                const value = context[0].parsed.y;

                return this.valueRenderer.displayValue(
                  value,
                  this.locale,
                  this.currency,
                );
              },
              label: (context) => {
                return context.label;
              },
            },
          },
        },
        responsive: true,
        scales: {
          x: {
            display: true,
            grid: {
              display: false,
            },
            ticks: {
              maxTicksLimit: 5,
              maxRotation: 0,
            },
          },
          y: {
            display: true,
            linear: true,
            beginAtZero: true,
            grace: "5",
            grid: {
              z: 100,
              borderDash: [12, 12],
            },
            ticks: {
              callback: (value, _index, _ticks) => {
                return this.valueRenderer.displayValue(
                  value,
                  this.locale,
                  this.currency,
                );
              },
            },
          },
        },
      };
    },

    currentTrend: function () {
      return this.trends[this.metric];
    },

    chartData: function () {
      return {
        labels: this.currentTrend.labels,
        datasets: [
          {
            fill: "origin",
            label: this.currentTrend.label,
            backgroundColor: (ctx) => {
              const canvas = ctx.chart.ctx;
              const gradient = canvas.createLinearGradient(0, 0, 0, 150);
              gradient.addColorStop(0, "rgb(0, 123, 255, 0.15)");
              gradient.addColorStop(1, "rgb(0, 123, 255, 0.02)");
              return gradient;
            },
            data: this.currentTrend.values,
            cubicInterpolationMode: "monotone",
            borderColor: "rgb(53, 121, 246)",
          },
        ],
      };
    },
  },
};
</script>
