import { Controller } from "@hotwired/stimulus";
import { useMutation } from "stimulus-use";

import {
  jpCalculateTaxFromTotal,
  convertToDecimalRate,
} from "../../shared/tax_calculator";

// This controller is used for the JP line items editing tool.
export default class extends Controller {
  static targets = [
    "alcoholInput",
    "alcoholInputs",
    "destroyMarker",
    "discountValue",
    "isAlcohol",
    "isConsumable",
    "category",
    "categoryName",
    "quantity",
    "reducedTaxRate",
    "required",
    "tax",
    "taxRatePercentage",
    "toggleAlcoholIcon",
    "toggleConsumableIcon",
    "toggleDiscount",
    "toggleReducedTaxRateIcon",
    "toggleTaxIncludedInTotalIcon",
    "taxIncludedInTotal",
    "totalPrice",
    "triggersRefresh",
    "unitPrice",
  ];

  connect() {
    // Use MutationObserver (via useMutaion wrapper from stimulus-use) to watch
    // for changes in the hidden input. This is necessary because hidden inputs
    // don't trigger "change" events normally.
    useMutation(this, {
      element: this.destroyMarkerTarget,
      attributeFilter: ["value"],
    });
  }

  mutate(entries) {
    // When a mutation is observed (i.e., the hidden input's value changes)
    // manually dispatch a "change" event for each mutation. This allows us
    // to react to changes in the hidden input as if it were a normal input.
    for (const entry of entries) {
      entry.target.dispatchEvent(new Event("change", { bubbles: true }));
    }
  }

  get isStandardTaxRate() {
    return this.taxRatePercentageTarget.value === "10";
  }

  get isReducedTaxRate() {
    return this.taxRatePercentageTarget.value === "8";
  }

  get isConsumable() {
    return this.isConsumableTarget.checked;
  }

  get taxIncludedInTotal() {
    return this.taxIncludedInTotalTarget.checked;
  }

  get isAlcohol() {
    return this.isAlcoholTarget.checked;
  }

  // Alcohol
  toggleAlcohol() {
    this.#togglePressed(this.toggleAlcoholIconTarget);

    if (this.isAlcoholTarget.checked) {
      this.enableAlcoholInputs();
    } else {
      this.disableAlcoholInputs();
    }
  }

  enableAlcoholInputs() {
    this.alcoholInputsTarget.classList.remove("d-none");
    this.alcoholInputTargets.forEach((element) => {
      element.disabled = false;
      element.required = true;
    });
  }

  disableAlcoholInputs() {
    this.alcoholInputsTarget.classList.add("d-none");
    this.alcoholInputTargets.forEach((element) => {
      element.disabled = true;
      element.required = false;
    });
  }

  // Reduced Tax Rate
  toggleTaxRate() {
    this.#togglePressed(this.toggleReducedTaxRateIconTarget);

    if (this.reducedTaxRateTarget.checked) {
      this.taxRatePercentageTarget.value = "8";
    } else {
      this.taxRatePercentageTarget.value = "10";
    }

    this.update();
  }

  // Consumable.
  toggleConsumable() {
    this.#togglePressed(this.toggleConsumableIconTarget);
  }

  // Tax Included in Total.
  toggleTaxIncludedInTotal() {
    this.#togglePressed(this.toggleTaxIncludedInTotalIconTarget);
  }

  get quantity() {
    return Number(
      this.quantityTarget.dataset.value || this.quantityTarget.value,
    );
  }

  get unitPrice() {
    return Number(
      this.unitPriceTarget.dataset.value || this.unitPriceTarget.value,
    );
  }

  get totalPrice() {
    return this.quantity * this.unitPrice;
  }

  get taxRate() {
    return convertToDecimalRate(this.taxRatePercentageTarget.value);
  }

  get tax() {
    return jpCalculateTaxFromTotal(
      this.totalPrice,
      this.taxRate,
      this.taxIncludedInTotal,
    );
  }

  get taxOverriden() {
    return this.taxOverridenTarget.checked;
  }

  update() {
    this.totalPriceTarget.value = this.totalPrice;

    this.taxTarget.value = this.tax;

    // We dispatch an event when the form is updated
    // so anything that relies on these calculations can
    // follow along
    this.dispatch("totalsUpdated");
  }

  markedForDestruction() {
    return this.destroyMarkerTarget.getAttribute("value") === "1";
  }

  #togglePressed(target) {
    target.classList.toggle("pressed");
  }
}
